<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      //- 搜尋bar
      b-card.col-lg-12.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 篩選查詢
              i.fe-settings.ml-2
            a.collapse-turn.mr-2(
              @click="searchConsoleVisible = !searchConsoleVisible",
              href="javascript: void(0);"
            )
              span(v-if="searchConsoleVisible") 收起
                i.fe-chevron-up
              span(v-else) 展開
                i.fe-chevron-down
            b-button.ml-1.width-lg(variant="primary", @click="reloadDataList") 查詢
            b-button.ml-1.width-lg(variant="secondary",@click="clearSearchField") 清除

        b-collapse#customaccordion-1(
          v-model="searchConsoleVisible",
          accordion="accordion",
          role="tabpanel"
        )
          b-card-body
            form.form-horizontal(role="form")
              .row
                .col-lg-4
                  .form-group
                    label.col-form-label(for="example-date") 開始時間
                    date-picker(
                      v-model="currentPageSearchOptions.start_date",
                      append-to-body,
                      lang="en",
                      confirm,
                      placeholder="請選擇起始日期"
                    )
                .col-lg-4
                  .form-group
                    label.col-form-label(for="example-date") 結束時間
                    date-picker(
                      v-model="currentPageSearchOptions.end_date",
                      append-to-body,
                      lang="en",
                      confirm,
                      placeholder="請選擇結束日期"
                    )
                .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 訂單編號
                    input.form-control(v-model="currentPageSearchOptions.number", type="text"  placeholder="請輸入訂單編號")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 贊助種類
                    multiselect(
                      track-by="id",
                      label="label",
                      v-model="currentBillSourceType", 
                      :options="BillSourceOptions", 
                      placeholder="請選擇贊助種類", 
                      select-label=""
                    )
                .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 付款結果
                    select.form-control(v-model="currentPageSearchOptions.pay_statys")
                      option(value="0") 全部結果
                      option(value="1") 已付款
                      option(value="2") 未付款
                      option(value="3") 付款失敗
                      option(value="4") 已退款
                .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 留言內容關鍵字
                    input.form-control(v-model="currentPageSearchOptions.word", type="text" placeholder="請輸入關鍵字")
                //- .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 姓名
                    input.form-control(v-model="currentPageSearchOptions.user_name", type="text" placeholder="請輸入姓名")
                //- .col-lg-4
                  .form-group.mb-3.p-0.m-0.mt-1
                    label Email
                    br
                    .row
                      .col-6.d-flex.justify-content-between.align-items-center
                        input.form-control(v-model="currentPageSearchOptions.email_account", type="text")
                        span.ml-3.mt-1 @
                      .col-6
                        input.form-control(v-model="currentPageSearchOptions.email_domain", type="text")
              //- .row
                .col-lg-4
                  .form-group.mb-3
                    label.col-form-label(for="form-control") 手機號碼
                    input.form-control(v-model="currentPageSearchOptions.cell_phone", type="text" placeholder="請輸入手機號碼")

      //- 表格
      table.col-12
        .card
          .card-body
            .header-row.mb-3
              .header-title 訂單列表
              p.mr-3.mt-2 符合條件資料共計有 {{ totalRows }} 筆資料
              b-button.width-lg.mr-2(variant="primary",@click="getExportFile") 匯出
            //- Table
            .table-responsive.mb-0
              b-table(
                ref="BillOrderList"
                :items="getOrderList", 
                :fields="BillOrderFields", 
                responsive, 
                :per-page="perPage", 
                :current-page="currentPage", 
                :sort-desc.sync="sortDesc", 
                :filter="filter", 
                :filter-included-fields="filterOn", 
                @filtered="onFiltered"
              )

                template(v-slot:cell(source)="row")
                  span(v-if="row.item.source == 1") 中天加油
                  span(v-if="row.item.source == 2") 贊助本文章
                  span(v-if="row.item.source == 3") 小編好棒棒
                  span(v-if="row.item.source == 4") 中天加油

                template(v-slot:cell(memberName)="row")
                  span {{ row.item.member.user_name }}

                template(v-slot:cell(memberEmail)="row")
                  span {{ row.item.member.user_email }}

                template(v-slot:cell(on_app)="row")
                  b-badge.badge-outline-dark(v-if="row.item.on_app == 0") WEB
                  b-badge.badge-outline-primary(v-if="row.item.on_app == 1") APP

                template(v-slot:cell(payment_source)="row")
                  span(v-if="row.item.payment_source == 0") 綠界信用卡
                  span(v-if="row.item.payment_source == 1") 綠界信用卡
                  span(v-if="row.item.payment_source == 2") Apple Pay

                template(v-slot:cell(status)="row")
                  b-badge.badge-outline-dark(v-if="row.item.status == 0") 尚未付款
                  b-badge.badge-outline-success(v-if="row.item.status == 1") 付款成功
                  b-badge.badge-outline-warning(v-if="row.item.status == 2") 付款失敗

                //- 編輯
                template(v-slot:cell(edit)="row")
                  b-button.table-btn(variant="primary" :to="'/ordermanagement-setting/' + row.item.id")
                    | 查看

              .row.mb-md-2
                .col-12
                  div(class="dataTables_paginate paging_simple_numbers float-right")
                    ul.pagination.pagination-rounded.mb-0
                      b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  name: 'OrderBillList',
  data () {
    return {
      title: "訂單管理",
      items: [
        {
          text: "贊助管理",
          href: "/ordermanagement",
        },
        {
          text: "訂單管理",
          active: true,
        }
      ],
      searchConsoleVisible: true,
      currentPageSearchOptions: {
        start_date: "",
        end_date: "",
        pay_statys: "0",
        number: "",
        source: "",
        user_name: "",
        email_account: "",
        email_domain: "",
        cell_phone: "",
        word: "",
      },
      currentBillSourceType: false,
      BillSourceOptions: [
        { id: 1, label: "中天加油" },
        { id: 2, label: "贊助本文章" },
        { id: 3, label: "小編好棒棒" },
        { id: 4, label: "中天加油" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],
      sortDesc: false,
      BillOrderFields: [
        { key: "time", label: "贊助時間", sortable: false, class: 'width-auto' },
        { key: "source", label: "贊助種類", sortable: false, class: 'width-auto' },
        { key: "number", label: "訂單編號", sortable: false, class: 'width-auto' },
        { key: "amount", label: "贊助金額", sortable: false, class: 'width-auto' },
        { key: "memberName", label: "姓名", sortable: false, class: 'width-auto' },
        { key: "memberEmail", label: "Email", sortable: false, class: 'width-auto' },
        { key: "on_app", label: 'APP', sortable: false, class: 'width-auto' },
        { key: "payment_source", label: "付款方式", sortable: false, class: 'width-auto' },
        { key: "status", label: "訂單狀態", sortable: false, class: 'width-auto' },
        { key: "edit", label: "操作", sortable: false, stickyColumn: true, class: 'width-auto text-center', stickyColumn: true },
      ],
    };
  },
  computed: {
  },
  mounted () {
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  methods: {
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearSearchField () {
      this.currentBillSourceType = false
      this.currentPageSearchOptions = {
        start_date: "",
        end_date: "",
        number: "",
        source: "",
        user_name: "",
        email_account: "",
        email_domain: "",
        cell_phone: "",
        word: "",
        pay_statys: "0"
      }
      this.reloadDataList()
    },
    reloadDataList () {
      this.$refs.BillOrderList.refresh()
    },
    async getOrderList (_table, _callback) {
      let vm = this
      let search = this.currentPageSearchOptions
      search.page = this.currentPage

      if (this.currentBillSourceType.id) {
        search.source = this.currentBillSourceType.id
      }

      await this.$root.apis.getBillList(search, async function (_response) {
        let response = _response.body.data
        vm.totalRows = response.total
        vm.currentPage = response.page
        vm.perPage = response.per_page

        if (typeof _callback === 'function') {
          await _callback(response.orders)
        }
      })
    },
    getExportFile () {
      let exportPath = this.$root.dashboard_uri + '/export/order-list'
      window.open(exportPath)
    }
  }
};
</script>

<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: auto
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0

::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
  
</style>
